import Swup from "swup";
// import SwupA11yPlugin from "@swup/a11y-plugin";
import SwupFadeTheme from "@swup/fade-theme";
import SwupPreloadPlugin from "@swup/preload-plugin";
import { tns } from "tiny-slider";

import "./site.css";

let slider;

// const log = () => {
//   console.group("Halo, grüetzi & hello! 💙");
//   console.log(
//     "¬ Welcome to Eysler.com, a site carefully crafted by Mogli Studio"
//   );
//   console.log("¬ Visit us over at https://moglistudio.com");
//   console.log("¬ Or simply reach out at hello@moglistudio.com");
//   console.log("Thanks! And have a wonderful day! 👋🏻");
//   console.groupEnd();
// };

const init = () => {
  // window magic
  window.scrollTo(0, 0);

  // navbar magic
  if (document.querySelector("#navbar")) {
    const navbar = document.querySelector("#navbar");
    const navbarToggle = document.querySelector("#navbar-toggle");
    setTimeout(() => navbar.classList.add("translate-y-full"), 750);

    navbarToggle.addEventListener("click", () =>
      navbar.classList.toggle("translate-y-full")
    );

    // toggle on scroll
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
      } else {
        document.getElementById("navbar").classList.add("translate-y-full");
      }
      prevScrollpos = currentScrollPos;
    };
  }

  // slider magic
  if (document.querySelector(".slider")) {
    slider = tns({
      arrowKeys: false,
      // autoHeight: true,
      autoplay: true,
      // autoWidth: true,
      autoplayButton: ".pause",
      container: ".slider",
      controls: false,
      items: 1,
      lazyload: true,
      mode: "gallery",
      nav: false,
      nextButton: false,
      prevButton: false,
      speed: 1500,
    });
  }

  // accordion magic
  if (document.querySelector("#ey-accordion")) {
    let titles = document.querySelectorAll(".ey-accordion-title");
    titles.forEach((title) => {
      let block = title.nextElementSibling;

      title.firstElementChild.addEventListener("click", (event) => {
        event.preventDefault();
        if (title.classList.contains("active")) {
          block.classList.add("h-0");
          block.classList.remove("h-auto");
          block.setAttribute("aria-hidden", true);
          title.classList.remove("active");
          title.firstElementChild.setAttribute("aria-expanded", false);
        } else {
          block.classList.add("h-auto");
          block.classList.remove("h-0");
          block.setAttribute("aria-hidden", false);
          title.classList.add("active");
          title.firstElementChild.setAttribute("aria-expanded", true);
        }
      });
    });
  }
};

const unload = () => {
  if (document.querySelector(".slider")) {
    slider.destroy();
  }
};

const swup = new Swup({
  containers: ["#swup", ".swup"],
  plugins: [
    // new SwupA11yPlugin(),
    new SwupPreloadPlugin(),
    new SwupFadeTheme({ mainElement: ["#swup"] }),
  ],
});

init();
// log();

swup.on("contentReplaced", init);
swup.on("willReplaceContent", unload);
